import axios from "axios";
import IFile from '../../domain/interfaces/IFile';

export default class DocumentService {

    private static _pdfjsLib;

    public static async download(fileToDownload, activeLanguge: string = '') {
        const file = this.getFile(fileToDownload, activeLanguge);
        try {
            const response = await this.getFileContent(file.urlToDownload);
            this.silentDownload({ fileName: file.fileName, urlToDownload: window.URL.createObjectURL(response) });
        } catch (error) {
            console.error(error);
        }
    }

    private static async getFileContent(fileUrl: string): Promise<any>  {
        try {
            const response = await axios.get(fileUrl, { responseType: 'blob' });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    private static silentDownload(fileToDownload) {
        const a: HTMLAnchorElement = document.createElement('a');
        a.href = fileToDownload.urlToDownload;
        a.download = fileToDownload.fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(fileToDownload.urlToDownload);
        document.body.removeChild(a);
    }

    private static getFile(file: any, activeLanguge: string): IFile {
        if (activeLanguge !== '') {
            return { fileName: `${file.name[activeLanguge]}${file.extension}`, urlToDownload: file.urlToDownload };
        }

        return { fileName: `${file.fileName}`, urlToDownload: file.urlToDownload };

    }

    /**
     * Checks if a file is a portfolio file type.
     * If document is encrypted it returns false to prevent a pdfjs library  error.
     * When document has only one attachment and is a xml file the document will not be a portfolio file type.
     * @param {File} file - The file to check.
     * @returns {Promise<boolean>} True if the file is a portfolio file type, false otherwise.
     */
    public static async isPdfAPortfolioFileType(file) {
        const bytes = await this.getFileAsUint8Array(file);
        const isFileEncrypted = await this.isFileEncrypted(bytes);
        if (isFileEncrypted) {
            return false;
        }
        await this.loadPdfjsLib();
        return new Promise<boolean>((resolve, reject) => {
            this._pdfjsLib.getDocument({ data: bytes }).promise.then(pdfDoc => {
                pdfDoc.getAttachments().then(attachments => {
                    resolve(attachments !== null && !this.hasOneAttachmentAndIsXmlFile(attachments));
                }).catch(error => {
                    reject(error);
                });
            }).catch((error) => {
                console.error(error);
            });
        });
    }

    /**
     * Checks if a file is encrypted.
     * @param {File | Uint8Array} file - The file to check.
     * @returns {Promise<boolean>} True if the file is encrypted, false otherwise.
     */
    public static async isFileEncrypted(file: any | Uint8Array) {
        const bytes = !(file instanceof Uint8Array) ? await this.getFileAsUint8Array(file) : file;
        const text = new TextDecoder().decode(bytes);
        return text.includes('/Encrypt');
    }

    /**
     * Returns the bytes of a file.
     * @param {File} file - The file to get.
     * @returns {Promise<Uint8Array>} The file as a Uint8Array.
     * @private
     */
    private static async getFileAsUint8Array(file) {
        return new Promise<Uint8Array>(resolve => {
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result;
                if (result !== null && result instanceof ArrayBuffer) {
                    resolve(new Uint8Array(result));
                }
            };
            reader.readAsArrayBuffer(file);
        });
    }

   /**
     * Returns if has only one attachment and is a xml file.
     * @returns {boolean}
     * @private
     */
   private static hasOneAttachmentAndIsXmlFile(attachments: [{filename: string, content: Uint8Array}]) {
    const namesFromAttachments = Object.values(attachments).map(attachment => attachment.filename);
    const hasOneElement = namesFromAttachments.length === 1;
    return hasOneElement && namesFromAttachments.some(fileName => fileName.endsWith('.xml'));
}
    
    /**
     * Load pdfjs library in the view model that is needed
     * @private
     */
    private static async loadPdfjsLib() {
        if(this._pdfjsLib) {
            return;
        }
        this._pdfjsLib = await import('pdfjs-dist');
        this._pdfjsLib.GlobalWorkerOptions.workerSrc = '../../public/pdf.worker.js';
    }
}
