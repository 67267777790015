import { computed, nextTick, ref, Ref } from "vue";
import { Constants } from "../../domain/enum/Constants";
import { Representative } from "../../domain/Representative";

interface useRepresentativeFormDependencies {
    checkValueAndUpdateInput: (form: HTMLFormElement) => void;
    isValidNie: (nif: string) => boolean;
    isValidNif: (nif: string) => boolean;
    isValidMail: (email: string) => boolean;
    municipalities: Ref<{disabled: boolean, id: number, name: string}[]>;
    getMasterDataMunicipalities: (province: number) => Promise<{data: {controlCode: number, isObsolete: false, municipalityCode: number, municipalityName: string, provinceCode: number}}>;
    setMunicipalities: (municipalities: any, municipalityInscription: any) => {disabled: boolean, id: number, name: string}[],
}

const applicantDataInitialState = {
    nif: '',
    name: '',
    firstSurname: '',
    secondSurname: ''
};

const representativeDataInitialState = {
    email: '',
    address: {
        country: Constants.country.SPAIN_CODE,
        province: '',
        foreignProvince: '',
        municipality: '',
        foreignMunicipality: '',
        streetType: '',
        streetName: '',
        number: '',
        portal: '',
        block: '',
        stair: '',
        floor: '',
        door: '',
        postCode: '',
        foreignPostCode: '',
    },
    authorizationFile: null
};

export const useRepresentativeForm = (dependencies: useRepresentativeFormDependencies) => {
    // dependencies
    const {
        checkValueAndUpdateInput,
        isValidNie,
        isValidNif,
        isValidMail,
        municipalities,
        getMasterDataMunicipalities,
        setMunicipalities
    } = dependencies;

    // view properties
    const applicantData: Ref<{ nif: string, name: string, firstSurname: string, secondSurname: string }> = ref(applicantDataInitialState);
    const representativeData = ref(new Representative(representativeDataInitialState));
    const representativeForm = ref(null);
    const representativeEmail = ref(null);
    const postCode = ref(null);
    const foreignPostCode = ref(null);
    const isRepresentative = ref(false);

    // methods
    const openRepresentativeForm = () => {
      setRepresentativeUseCaseToInitialState();
      isRepresentative.value = true;
    };

    const setRepresentativeUseCaseToInitialState = () => {
      if(isRepresentative.value) {
        isRepresentative.value = false;
      }
      applicantData.value = { ...applicantDataInitialState };
      representativeData.value = new Representative(representativeDataInitialState);
    };

    const onRepresentativeCountryChange = () => {
      nextTick(() => {
        if (!representativeForm.value) {
          return;
        }
        checkValueAndUpdateInput(representativeForm.value);
        representativeData.value.address.countryCode = Number(representativeData.value.address.country);
      });
    };

    const onRepresentativeProvinceChange = () => {
      (representativeData.value.address.municipality as any) = null;
      if (representativeData.value.address.province) {
        municipalities.value = [];
        nextTick(() => {
          getMasterDataMunicipalities(representativeData.value.address.province).then((municipalitiesData: any) => {
            municipalities.value = setMunicipalities(municipalitiesData.data, representativeData.value.address.municipality);
          }).catch((error) => {
            console.error(error);
          });
        });
      }
    };

    const onInputRepresentativeAuthorizationFile = (file: any) => {
      if (file) {
        file.fileName = file.name;
        file.allowRemove = true;
        representativeData.value.authorizationFile = file;
      }
    };

    const onRemoveRepresentativeAuthorizationFile = () => {
      representativeData.value.authorizationFile = null;
    }

    // computed
    const representativeDocuments = computed(() => representativeData.value.authorizationFile ? [representativeData.value.authorizationFile] : [])
    const anyFieldRequiredEmpty = computed(() => {
        const applicantDataValues = Object.values(applicantData.value);
        applicantDataValues.pop();
        return (applicantDataValues.some((element) => element === '') || !representativeData.value.isValidForm());
    });
    const isApplicantNifValid = computed(() => applicantData.value.nif && (isValidNie(applicantData.value.nif) || isValidNif(applicantData.value.nif)));
    const isRepresentativeMailValid = computed(() => representativeData.value.email && isValidMail(representativeData.value.email));
    const isRepresentativeFormInvalid = computed(() => anyFieldRequiredEmpty.value || !isApplicantNifValid.value || !isRepresentativeMailValid.value);

    return {
        // view properties
        applicantData,
        representativeData,
        isRepresentative,
        // refs
        representativeForm,
        representativeEmail,
        postCode,
        foreignPostCode,
        // methods
        setRepresentativeUseCaseToInitialState,
        onRepresentativeCountryChange, 
        onRepresentativeProvinceChange, 
        onInputRepresentativeAuthorizationFile, 
        onRemoveRepresentativeAuthorizationFile,
        openRepresentativeForm,
        // computed
        representativeDocuments,
        anyFieldRequiredEmpty,
        isApplicantNifValid,
        isRepresentativeFormInvalid
    };
};
