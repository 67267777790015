import { IRepresentativeRepository } from '../../domain/interfaces/IRepresentativeRepository';
import { Representative } from '../../domain/Representative';

export class RepresentativeRepository implements IRepresentativeRepository {
  
  private readonly dbName = 'applicantDB';
  private readonly storeName = 'representatives';
  private readonly recordId = 'authorizationFile';

  private async openDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1);

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        if (!db.objectStoreNames.contains(this.storeName)) {
          db.createObjectStore(this.storeName, { keyPath: 'id' });
        }
      };

      request.onsuccess = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        resolve(db);
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  public async saveRepresentative(representative: Representative): Promise<void> {
    const db = await this.openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.storeName, 'readwrite');
      const store = transaction.objectStore(this.storeName);
      const representativeRecord = { id: this.recordId, representative: representative.toSaveOnRepo() };
      const request = store.put(representativeRecord);

      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    });
  }

  public async getRepresentative(): Promise<Representative | null> {
    const db = await this.openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.storeName, 'readonly');
      const store = transaction.objectStore(this.storeName);
      const request = store.get(this.recordId);

      request.onsuccess = () => {
        const result: ({ id: string, representative: Object } | null) = request.result;
        resolve(result && result.representative ? new Representative(result.representative) : null);
      };

      request.onerror = () => reject(request.error);
    });
  }

  public async deleteRepresentative(): Promise<void> {
    const db = await this.openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.storeName, 'readwrite');
      const store = transaction.objectStore(this.storeName);
      const request = store.delete(this.recordId);

      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    });
  }
}
