import moment from 'moment';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SdDocumentViewer',
  props: {
    documentsToManage: {
      type: Array as PropType<any[]>,
      required: false,
      default: () => [],
    },
  },
  emits: ['removeFile', 'downloadFile'],
  setup(props, { emit }) {
    const dateFormat = 'YYYY-MM-DD';

    const documentsToManageFiltered = computed(() => {
      return props.documentsToManage
        ? props.documentsToManage.filter((document: any) => !document.delete)
        : [];
    });

    const removeFile = (document: any) => {
      emit('removeFile', document);
    };

    const downloadFile = (document: any) => {
      emit('downloadFile', document);
    };

    const hourFormat = (document: any) => {
      const hourFormat = 'HH:mm';
      return document.fileDate
        ? moment(document.fileDate.toISOString()).format(hourFormat)
        : '';
    };

    return {
      dateFormat,
      removeFile,
      downloadFile,
      hourFormat,
      documentsToManageFiltered,
    };
  },
});
