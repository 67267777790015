import { nextTick, Ref, ref } from "vue";

interface useMasterDataAddressDependencies {
    orderSelect: any;
    getMasterDataCountries: () => Promise<{ data: { [key: string]: string } }>;
    getMasterDataAutonomousCommunity: () => Promise<{ data: { [key: string]: string } }>;
    getMasterDataStreetTypes: () => Promise<{ data: { [key: string]: string } }>;
    getMasterDataProvinces: () => Promise<{ data: { [key: string]: string } }>;
    checkValueAndUpdateInput: (form: HTMLFormElement) => void;
}

export const useMasterDataAddress = (dependencies: useMasterDataAddressDependencies) => {
    const countries: Ref<{ [key: string]: string }> = ref({});
    const autonomousCommunityList: Ref<{ [key: string]: string }> = ref({});
    const streetTypes: Ref<{ [key: string]: string } | null> = ref({});
    const provinces: Ref<{ [key: string]: string }> = ref({});
    const loadingStreetTypes: Ref<boolean> = ref(false);
    const { orderSelect, getMasterDataCountries, getMasterDataAutonomousCommunity, getMasterDataStreetTypes, getMasterDataProvinces, checkValueAndUpdateInput } = dependencies;

    const getMasterDataAddress = () => {
        getMasterDataCountries()
            .then((responseCountries) => {
                countries.value = responseCountries.data;
            })
            .catch((error) => {
                console.error(error);
            });

        getMasterDataAutonomousCommunity()
            .then((responseAutonomousCommunity) => {
                autonomousCommunityList.value = orderSelect.prepareDataNotArray(responseAutonomousCommunity.data);
            })
            .catch((error) => {
                console.error(error);
            });

        getMasterDataStreetTypes()
            .then((responseDataStreetTypes) => {
                streetTypes.value = orderSelect.prepareDataNotArray(responseDataStreetTypes.data);
            })
            .catch((error) => {
                console.error(error);
            });

        getMasterDataProvinces()
            .then((responseProvinces) => {
                provinces.value = orderSelect.prepareDataNotArray(responseProvinces.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const refreshStreeTypes = (formRef: Ref) => {
        loadingStreetTypes.value = true;
        streetTypes.value = null;
        getMasterDataStreetTypes().then((responseDataStreetTypes) => {
            streetTypes.value = orderSelect.prepareDataNotArray(responseDataStreetTypes.data);
            loadingStreetTypes.value = false;
            if (formRef.value) {
                nextTick(() => {
                    checkValueAndUpdateInput(formRef.value);
                });
            }
        }).catch((error) => {
            console.error(error);
        });
    };

    return {
        // view poroperites
        countries,
        autonomousCommunityList,
        streetTypes,
        provinces,
        loadingStreetTypes,
        // methods
        getMasterDataAddress,
        refreshStreeTypes
    };
};