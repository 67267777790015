import axios, { AxiosResponse } from "axios";
import {masterDataCountries, masterDataStreetTypes, masterDataProvinces, masterDataAutonomousCommunity, masterDataHeaderConfiguration, masterDataClientInfo, masterDataNationalities, masterDataMunicipalities, masterDataLanguajes, masterDataFlags, processesConfigurationPayExemptionList, processesConfigurationAuthorizationList, processesConfigurationGroup, clientProviders } from "./services.config";
import IHeaderConfig from "../../domain/interfaces/IHeaderConfig";

export let getMasterDataCountries = function (): Promise<{ data: { [key: string]: string } }> {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataCountries()));
    })
}

export let getMasterDataStreetTypes = function (): Promise<{ data: { [key: string]: string } }> {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataStreetTypes()));
    })
}

export let getMasterDataProvinces = function (): Promise<{ data: { [key: string]: string } }> {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataProvinces()));
    })
}

export let getMasterDataMunicipalities = function (provinceId: number): Promise<{data: {controlCode: number, isObsolete: false, municipalityCode: number, municipalityName: string, provinceCode: number}}> {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataMunicipalities(provinceId)));
    })
}

export let getMasterDataAutonomousCommunity = function (): Promise<{ data: { [key: string]: string } }> {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataAutonomousCommunity()));
    })
}


export const getHeaderConfiguration = async (): Promise<AxiosResponse<IHeaderConfig>> => {
    try {
      const response = await axios.get(masterDataHeaderConfiguration());
      return response;
    } catch (error) {
      throw error;
    }
};

export let getClientInfo = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataClientInfo()));
    })
}

export let getMasterDataNationalities = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataNationalities()));
    })
}

export const getClientAvailableLanguajes = async (): Promise<AxiosResponse<{ defaultLanguage: string, secondLanguage: string}>> => {
    try {
      const response = await axios.get(masterDataLanguajes());
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
};

export const getFeatureFlag = async (): Promise<AxiosResponse<string[]>> => {
    try {
      const response = await axios.get(masterDataFlags());
      return response;
    } catch (error) {
      throw error;
    }
  };

export let getProcessesConfigurationPayExemptionList = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(processesConfigurationPayExemptionList()));
    })
}

export let getProcessesConfigurationAuthorizationList = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(processesConfigurationAuthorizationList()));
    })
}

export let getProcessesConfigurationGroup = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(processesConfigurationGroup()));
    })
}

export let getClientProviders = function() {
    return new Promise((resolve, reject) => {
        resolve(axios.get(clientProviders()));
    })
}
