import IEnum from '../interfaces/IEnum';

export const Constants: IEnum = {
    APP: '#app',
    language: {
        ES_ES: 'es-ES',
        CA_ES: 'ca-ES',        
        EU_ES: 'eu-ES'
    },
    format: {
        L: 'L',
    },
    fileImporter: {
        VALID_EXTENSIONS: ['.bmp', '.jpg', '.jpeg', '.pdf', '.png'],
        INVALID_FILE_NAME: ['"', '<', '>', '|', ':', '*', '?', '/', '\\'],
        PDF_EXTENSION: '.pdf',
        CSV_EXTENSION: '.csv',
        MAX_SIZE_FILE: 10 * 1024 * 1024 // 10MB on binary sense
    },
    country: {
        SPAIN_CODE: 724
    },
    publications: {
        AMOUNT_OF_PUBLICATIONS: 4
    },
    inscription: {
        MAX_YEARS: 99.999,
        MAX_FULL_YEARS: 99,
        MAX_USER_OFICIO_COMMENT: 500,
        POST_MERIT_STEP: 12,
        STEP_MERIT: 3
    },
    keyCodes: {
        COMMA_KEY_CODE: 44,
        DOT_KEY_CODE: 46,
        ZERO_KEY_CODE: 48,
        NINE_KEY_CODE: 57,
    },
    postalCode: {
        FIVE_ZEROS: '00000' // POSTAL CODE
    },
    savia: {
        googleAnalyticsOptInBrowserAddOn: 'https://tools.google.com/dlpage/gaoptout?hl=de%3Cbr/',
        sedeAGPD: 'https://sedeagpd.gob.es/sede-electronica-web/',
        dpoEmail: 'dpo@savia.net',
        urlSavia: 'https://www.savia.net/',
        phone: '913142815',
        saviaEmail: 'savia@savia.net'
    },
    twoFA: {
        twofaRememberedDeviceXXXX: '2faRememberedDeviceXXXX'
    },
    sharedLengths: {
        FIVE_HUNDRED: 500
    },
    minimumInscriptionCandidateAge: 16,
    maximumInscriptionCandidateAge: 70,
};
