import { computed, defineComponent, reactive, toRefs, defineProps, defineEmits} from 'vue'
import * as imageCompressor from 'saviafront/lib/js/imageCompressor';
import { Constants } from "../../../domain/enum/Constants";
import moment from 'moment';
import DocumentService from '../../services/DocumentService';
import SdDocumentViewer from '../SdDocumentViewer/SdDocumentViewer.vue';

export default defineComponent({
    components: { SdDocumentViewer },
    name: 'SdFileImporter',
    props: {
        required: { type: Boolean, required: false, default: () => false },
        disabled: { type: Boolean, required: false, default: () => false },
        submitted: { type: Boolean, required: false, default: () => false },
        label: { type: String, required: false },
        multipleFiles: { type: Boolean, required: false, default: () => false },
        documentsToManage: { type: Array, required: false, default: () => []  },
        xsScroll: { type: Boolean, required: false },
        disableOneFile: { type: Boolean, required: false },
        onlyCsv: { type: Boolean, required: false, default: () => false },
    },
    emits: ['input', 'removeFile', 'downloadFile'],
    setup(props, { emit }) {
        const state = reactive({
            dragging: false,
            dirty: false,
            maximumFilesExceeded: false,
            filesError: [],
            isFileEncrypted: false,
            isAPortfolioFile: false,
            importer: null
        });
        // computed properties
        const hasRequiredError = computed(() => props.required && (state.dirty || props.submitted) && props.documentsToManage && props.documentsToManage?.length < 1);
        // methods
        const resetState = () => {
            state.dragging = false;
            state.dirty = false;
            state.maximumFilesExceeded = false;
            state.filesError = [];
            state.isFileEncrypted = false;
            state.isAPortfolioFile = false;
        };
        const removeFileError = (fileErrorToRemove: any) => {
            const index = state.filesError.findIndex((file: any) => file.name === fileErrorToRemove.name);
            state.filesError.splice(index, 1);
        };
        const removeFile = (document: any) => {
            emit('removeFile', document);
        };
        const downloadFile = (document: any) => {
            emit('downloadFile', document);
        };
        const onFileChange = async ($event: any) => {
            state.filesError = [];
            state.maximumFilesExceeded = false;
            state.dirty = true;

            if (props.disabled) {
                return;
            }
            let files: any = [];
            if ($event.dataTransfer) {
                props.multipleFiles ? files = [...$event.dataTransfer.files] : files.push($event.dataTransfer.files[0]);
            } else {
                props.multipleFiles ? files = [...state.importer?.files] : files.push(state.importer?.files[0]);
            }

            if (files.length > 10) {
                state.dragging = false;
                state.maximumFilesExceeded = true;
                return;
            }
            for (const element of files) {
                const fileExtension = '.' + element.name.split('.').pop();
                if (fileExtension.toLowerCase() === Constants.fileImporter.PDF_EXTENSION) {
                    state.isFileEncrypted = await DocumentService.isFileEncrypted(element);
                    state.isAPortfolioFile = await DocumentService.isPdfAPortfolioFileType(element);
                }
                validateFile(element, fileExtension);
                state.dragging = false;
                if (element.errors.length) {
                    element.extension = fileExtension;
                    state.filesError.push(element);
                    continue;
                }
                if (fileExtension.toLowerCase() !== Constants.fileImporter.PDF_EXTENSION && fileExtension.toLowerCase() !== Constants.fileImporter.CSV_EXTENSION) {
                    await imageCompressor.compress(element);
                }
                element.extension = fileExtension;
                element.fileDate = moment();
                emit('input', element);
            }
            $event.target.value = null;
        };
        const validateFile = (file: any, fileExtension: string) => {
            file.errors = [];
            if (Constants.fileImporter.INVALID_FILE_NAME.some((validFormat: string) => file.name.includes(validFormat))) {
                file.errors.push('lang.fileImporter.fileName');
            }
            if (!props.onlyCsv && !Constants.fileImporter.VALID_EXTENSIONS.includes(fileExtension.toLowerCase())) {
                file.errors.push('lang.fileImporter.fileType');
            }
            if (props.onlyCsv && Constants.fileImporter.CSV_EXTENSION !== fileExtension.toLowerCase()) {
                file.errors.push('lang.fileImporter.onlyCsv');
            }
            if (file.size > Constants.fileImporter.MAX_SIZE_FILE) {
                file.errors.push('lang.fileImporter.sizeFile');
            }
            if (state.isFileEncrypted) {
                file.errors.push('lang.fileImporter.fileEncrypted');
            }
            if (state.isAPortfolioFile) {
                file.errors.push('lang.fileImporter.isAPortfolioFile');
            }
        };

        return {
            ...toRefs(props),
            ...toRefs(state),
            hasRequiredError,
            resetState,
            removeFileError,
            removeFile,
            downloadFile,
            onFileChange
        }
    }
});

