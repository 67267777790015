<section class="sd-file-importer">
    <section class="sd-file-importer__wrapper form-element-validation" :class="{'sd-file-importer__error': hasRequiredError || maximumFilesExceeded, 'sd-file-importer__dropzone-over': dragging && !disabled, 'sd-file-importer__display-none': disableOneFile && documentsToManage.length === 1}"
    @drop.prevent="onFileChange" @dragover.prevent="dragging=true" @dragleave.stop.prevent="dragging=false">
        <div class="sd-file-importer__disabled" v-if="disabled"></div>
        <label class="sd-file-importer__label">{{label}}</label>
        <section class="t-center">
            <i class="fas fa-file-plus sd-file-importer__icon"></i>
            <p class="sd-file-importer__title">{{ $t('lang.fileImporter.updloadFile') }}</p>
        </section>
        <section class="t-center">
            <p class="sd-file-importer__subtitle d-i-block">{{ $t('lang.fileImporter.dragOr') }}</p>
            <div class="sd-file-importer__input-file">
                <span class="t-primary t-bold c-pointer" @click="importer.click()" id="button_doc">{{ $t('lang.fileImporter.searchFile') }}</span>
                <input type="file" placeholder="" title="" :multiple="multipleFiles" class="d-none" ref="importer" @input="resetState" @change="onFileChange" :required="required" :disabled="disabled" data-test-id="input-add-file">
            </div>
            <p class="sd-file-importer__subtitle d-i-block">{{ $t('lang.fileImporter.fileComputer') }}</p>
        </section>
        <p class="sd-file-importer__subtitle">{{ onlyCsv ? $t('lang.fileImporter.validTypeCsv') : $t('lang.fileImporter.validTypes')}}</p>
    </section>
    <section v-if="hasRequiredError || maximumFilesExceeded">
        <div class="sd-file-importer__error-type t-capitalize" v-if="hasRequiredError">{{ $t('lang.input.required') }}</div>
        <div class="sd-file-importer__error-type" v-if="maximumFilesExceeded">{{ $t('lang.input.maximumFilesExceeded') }}</div>
    </section>
    <section class="sd-file-importer__documents scrollbar" v-if="filesError.length > 0 || documentsToManage.length > 0" :class="{'sd-file-importer__xs-scroll': xsScroll}">
        <section class="sd-file-importer__document sd-file-importer__document--error" v-for="file in filesError">
            <div>
                <p class="sd-file-importer__error-file-name">{{`${file.name} [${Math.round(file.size / 1024)}kb, ${file.extension.toUpperCase()}]`}}</p>
                <div>
                    <div v-for="file in file.errors">{{ $t(file) }}</div>
                </div>
            </div>
            <div class="sd-file-importer__actions">
                <i class="fas fa-trash-alt sd-file-importer__delete-file" @click="removeFileError(file)"></i>
            </div>
        </section> 
        <sd-document-viewer v-if="documentsToManage.length > 0" :documents-to-manage="documentsToManage" @downloadFile="downloadFile" @removeFile="removeFile" />
    </section>
</section>