import { Constants } from "../../domain/enum/Constants";
import Cookies from "js-cookie";
import { AxiosResponse } from "axios";
import IHeaderConfig from "../../domain/interfaces/IHeaderConfig";
import ICommonDataState from "../../domain/interfaces/ICommonDataState";
import { App } from "../../domain/App";
import { Cookie } from "../../domain/Cookie";


interface UseCommonDataUseCase {
    updateCommonData: (state: ICommonDataState) => void;
    getHeaderConfiguration: () => Promise<AxiosResponse<IHeaderConfig>>;
    getFeatureFlag: () => Promise<AxiosResponse<string []>>;
    getClientAvailableLanguajes: () => Promise<AxiosResponse<{ defaultLanguage: string, secondLanguage: string}>>;
}

const initialState: ICommonDataState = {
    availableLanguajes: { defaultLanguage:  Constants.language.ES_ES, secondLanguage: '' },
    locale: Constants.language.ES_ES,
    app: new App(),
    cookie: new Cookie(),
    headerConfig: {
        title: null,
        image: null,
        favicon: {
          title: '',
          image: null,
        },
        host: '',
        name: '',
        emailSender: '',
        clientId: '',
        cif: '',
        email: '',
        urlElectronicHeadquarters: null,
        dpoEmail: '',
        address: '',
        phoneNumber: 0,
        replacementListsVisible: false,
        hasRegistryApi: false,
        hasOnlinePayment: false,
        hasPasarelaPayment: false,
        backgroundColor: '',
        textColor: '',
        security: {
          twoFactorMandatory: false,
          verificationCodeExpirationTimeInMinutes: 0,
          timeRequest2FAAgainInDays: 0,
        },
    }
}

export const useCommonData = function (dependencies: UseCommonDataUseCase) {
    const { updateCommonData, getHeaderConfiguration, getFeatureFlag, getClientAvailableLanguajes } = dependencies;
    const state: ICommonDataState = initialState;

    const setUpClientLanguages = async () => {
        try {
            const response = await getClientAvailableLanguajes();
            state.availableLanguajes = response.data;
            state.locale = state.app.getLanguaje(response.data.defaultLanguage);
            const langs = [response.data.defaultLanguage, response.data.secondLanguage].filter(Boolean);
            state.app.headerLangs = langs;
        } catch (error) {
            console.error(error);
        }
    };

    const setUpFeatureFlags = async () => {
        try {
            const response = await getFeatureFlag();
            response.data.map(element => state.app.featureFlags[element] = true);
            state.app.isLanguajeLoad = true;
        } catch (error) {
            console.error(error);
            state.app.isLanguajeLoad = true;
        }
    };

    const setUpCookies = () => {
        state.cookie.hasOptanonAlertBoxClosed = Cookies.get('OptanonAlertBoxClosed') !== undefined;
        if (state.cookie.hasOptanonAlertBoxClosed) {
            state.cookie.hasAcceptedAnalysisCookies = Cookies.get('AcceptedAnalysisCookies') !== undefined;
        }
        state.cookie.setConfigCookies();
    };

    const setUpHeaderConfig = async () => {
        try {
            const response = await getHeaderConfiguration();
            state.headerConfig = response.data;

            const link: any = document.querySelector("link[rel~='icon']");
            let title = document.getElementById('title');
            if (state.headerConfig.favicon !== null && state.headerConfig.favicon.image !== null) {
                link.href = 'data:image;base64,' + state.headerConfig.favicon.image;
                title!.innerText = state.headerConfig.favicon.title;
            } else {
                link.href = './convoca_logo.png';
                title!.innerText = 'Oferta de empleo público';
            }
            if ((state.app.state === 'replacementList' || state.app.state === 'replacementListDetail') && !state.headerConfig.replacementListsVisible ||
                (state.app.state === 'paymentRefund' && !state.app.featureFlags.paymentRefund)) {
                location.href = state.app.origin + '/index.html';
            }

            const backgroundColor = state.headerConfig.backgroundColor || '#1976d2';
            const textColor = state.headerConfig.textColor || '#ffffff';

            const elements = document.querySelectorAll('.applicant-nav, .applicant-footer');

            elements.forEach(element => {
                let nodeItemAsHtml: HTMLElement = element as HTMLElement;
                nodeItemAsHtml.style.backgroundColor = backgroundColor;
                nodeItemAsHtml.style.color = textColor;
            });
        } catch (error) {
            console.error(error);
        }
    };

    const loadCommonData = async () => {
        try {
            setUpCookies();
            await setUpClientLanguages();
            await setUpFeatureFlags();
            await setUpHeaderConfig();
            updateView()
        } catch (error) {
            console.error(error);
            updateCommonData(state);
        }
    };

    const updateView = () => {
        updateCommonData(state);
    }

    return {
        loadCommonData
    };
}