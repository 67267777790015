<section class="sd-document-viewer">
    <section class="sd-document-viewer__document" v-for="document in documentsToManageFiltered">
        <div>
            <p class="t-bold" v-if="document.fileName">{{document.fileName}}</p>
            <p class="sd-document-viewer__info-uploader" :title="document.uploaderName ? $t('lang.shared.uploaded') + ' ' + $t('lang.shared.for') + ' ' + document.uploaderNameAdmin() + ' ' + $t('lang.shared.the') + ' ' + document.fileDate.format(dateFormat) + ' ' + '[' + hourFormat(document) + ']' + ' [' + Math.round(document.size / 1024) + 'kb, ' + document.extension.toUpperCase() + ']'
            : $t('lang.shared.uploaded') + ' ' + $t('lang.shared.the') + ' ' + document.fileDate.format(dateFormat) + ' ' + '[' + hourFormat(document) + ']' + ' [' + Math.round(document.size / 1024) + 'kb, ' + document.extension.toUpperCase() + ']'">
                {{$t('lang.shared.uploaded')}} <span v-if="document.uploaderName">{{$t('lang.shared.for')}}</span> <span v-if="document.uploaderName">{{document.uploaderNameAdmin()}}</span> <span>{{$t('lang.shared.the')}}</span>{{` ${document.fileDate.format(dateFormat)} [${hourFormat(document)}] [${Math.round(document.size / 1024)}kb, ${document.extension.toUpperCase()}]`}}
            </p>
        </div>
        <div class="sd-document-viewer__actions">
            <i class="fas fa-check-circle sd-document-viewer__check-file" v-if="document.id"></i>
            <i class="fal fa-download sd-document-viewer__download-file" v-if="document.id" @click="downloadFile(document)"></i>
            <i class="fas fa-trash-alt sd-document-viewer__delete-file" v-if="document.allowRemove" @click="removeFile(document)"></i>
        </div>
        <section class="sd-document-viewer__loader-content" v-if="document.loading">
            <section class="sd-document-viewer__loader" :class="{'sd-document-viewer__loader--animation': document.loading}"></section>
        </section>
    </section>
</section>